.navbar {
    padding: 0.5rem 2rem 0 2rem;
    background-color: rgba(255, 255, 255, 0.883);
    position: fixed;
    z-index: 100;
    top:0;
    left: 0;
    right: 0;
    backdrop-filter: blur(5px);
}

.navbar--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto 0 auto;
}

.navbar--right {
    display: flex;
    align-items: center;
    gap: 1rem;  
}

.dropwdown--wrapper {
    position: fixed;
    inset: 0;
    z-index: 300;
    height: 100vh;
}

.logo--container {
    width: 100px;
}

.logo--container img {
    width: 100%;
}

.navbar--menu {
    display: flex;
    list-style: none;
}

.desktop-menu {
    display: none;
    gap: 3rem;
}

.desktop-menu ul {
    list-style: none;
}

.services-menu svg {
    transform: translate(0.25rem, .15rem);
}

.hamburger-btn {
    background-color: transparent;
    border: none;
}

.service-dropdown {
    overflow: hidden;
    position: absolute;
    width: 150%;
    padding: 0;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.883);
}

.service-dropdown li {
    padding: .25rem .5rem;
    color: #666;
    background-color: rgba(255, 255, 255, 0.883);
}

.service-dropdown li:first-child {
    margin-top: 1rem;
}

.service-dropdown li:hover {
    background-color: #444;
    color: white;
}



@media (min-width: 1080px) {
    .desktop-menu {
        display: flex;
    }

    .hamburger-btn {
        display: none;
    }
    
}