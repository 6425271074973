.why {
    max-width: 1200px;
    margin: 8rem auto 0 auto;
    padding: 0 1rem;
}

.why h3 {
    text-align: center;

}

.cards--container {
    margin-top: 4rem;
    gap: 1rem;
    text-align: center;
}

.cards--container img {
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
    max-width: 500px;
}

.card--container {
    margin: 4rem auto;
    max-width: 600px;
}

.card--container h4 {
    margin: 1rem 0;
}

@media (min-width: 1024px) {
    .cards--container {
        display: flex;
    }

    .card--container {
        padding: 0;
    }
}