.packages--grid {
    display: grid;
    column-gap: 2rem;
    row-gap: 3rem;
    padding: 4rem 0 0 0;
}

.packages--card {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.packages--card img {
    width: 100%;
}

.packages--title, .packages--price {
    text-align: center;
}

.packages--price {
    font-weight: 600;
}

.packages--book {
    margin: 1rem auto 0 auto;
}

@media (min-width: 768px) {
    .packages--grid {
        grid-template-columns: 1fr 1fr;
    }
}