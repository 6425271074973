.dropdown {
    background-color: white;
    border-radius: 1rem;
    border: 1px solid silver;
    padding: 1rem 1rem;
    text-align: center;
    display: grid;
    place-content: center;
    margin: 6rem 1rem 0 1rem;
}

