.contact {
}

.contact--container {
    display: grid;
    max-width: 1200px;
    margin: auto;
}

.contact--left img {
    width: 100%;
    object-fit: cover;
}

.contact--right {
    padding: 1rem 2rem;
}

@media (min-width: 768px) {
    .contact--container {
        grid-template-columns: 1fr 1fr;
    }
}