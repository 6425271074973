@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body {
  --main: #E21512;
  --dark: #545454;
  --light: #efefef;
  --bg2: #F8F0E6;
  font-family: "Inter", sans-serif;
  color: var(--dark);
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

strong {
  color: var(--main);
  font-style: italic;
}

h2, h3, h4, h5 {
  font-family: "Lora", serif;
  color: black;
}

h2 {
  font-size: 3rem;
  font-weight: 400;
}

h3 {
  font-size: 2.8rem;
  margin-bottom: .5rem;
}

h4 {
  font-size: 1.4rem;
}

form {
  margin-top: 1rem;
  display: flex;
  gap: .75rem;
  flex-direction: column;
}

label {
  font-size: 1.3rem;
}

input, textarea, select {
  margin-top: .25rem;
  display: block;
  width: 100%;
  font-size: 1.2rem;
  border-radius: .25rem;
  padding: .25rem;
  border: 1px solid silver;
}

textarea {
  min-height: 100px;
}

@media (min-width: 768px) {
  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 3.5rem;
  }

  h4 {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  h2 {
    font-size: 4.5rem;
  }

  h3 {
    font-size: 4rem;
  }

  h4 {
    font-size: 2.5rem;
  }
}

.page {
  margin: 6rem 0 0 0;
}

.page-hero {
  position: relative;
  padding: 1rem;
  min-height: 600px;
  background-size: cover;
  padding: 2rem 1rem;
}

.page-hero h2 {
  color: var(--light);
  text-transform: uppercase;
}

.page-hero h3 {
  color: var(--light);

}

.page-hero--cta {
  position: absolute;
  bottom: 2rem;
  display: grid;
  gap: 1rem;
  left: 5%;
}

@media (min-width: 1200px) {
  .page-hero--cta {
    grid-template-columns: 1fr 1fr;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto 0 auto;
}

.btn--main {
  background-color: var(--main);
  color: white;
  padding: 1rem 2rem;
  border-radius: .5rem;
  font-size: 1.2rem;
  text-align: center;
  transition: all .25s ease;
  border: none;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
}

.btn--main:hover {
  background-color: transparent;
  border: 1px solid var(--main);
  color: var(--main);
  scale: 1.05;
}

.btn--secondary {
  color: var(--main);
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: 1px solid var(--main);
  transition: all .25s ease;
  
}

.btn--secondary:hover {
  background-color: var(--main);
  color: white;
}

.success {
  color: blue;
  margin-top: 2rem;
  font-size: 1.2rem;
}

.collapsed {
  max-height: 0;
}

.hide-behind {
  scale: 0;
}

.transition-all {
  transition: all .25s ease;
}

.active-navbar {
  font-weight: 600;
  cursor: auto;
}