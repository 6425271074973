.contact-info {
    margin-top: 3rem;
    list-style: none;
}

.contact-info li {
    margin-top: .5rem;
}

.contact-info a:hover {
    color: black;
}

