.staging {
}

.staging--hero {
    background-image: url('https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_38.png?v=1718892991');
    background-position: 80% 20%;
}

.furniture-rental--intro {
    margin-top: 3rem;
    padding: 0 1rem;
}

.furniture-rental--intro--grid {
    display: grid;
    column-gap: 4rem;
    row-gap: 1rem;
}

.furniture-rental--intro--left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.furniture-rental--intro--right img {
    width: 100%;
}

@media (min-width: 1080px) {
    .furniture-rental--intro--grid {
        grid-template-columns: 1fr 1fr;
    }

    .furniture-rental--intro--right {
        margin-top: 5rem;
        padding: 0 1rem;
    }
}

.furniture-rental--how {
    margin-top: 6rem;
}

.furniture-rental--how--grid {
    display: flex;
    column-gap: 4rem;
    flex-direction: column-reverse;
    padding: 1rem;
}



.furniture-rental--how--left img {
    width: 100%;
}

.how--cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem;
}

.how--card {
    display: flex;
    gap: 1.5rem;
}

.how--number {
    font-size: 2.5rem;
}

@media (min-width: 1080px) {
    .furniture-rental--how--grid {
        flex-direction: row;
    }
}

.furniture-rental--packages {
    margin-top: 6rem;
    padding: 1rem;
}

.furniture-rental--upgrade {
    background-color: var(--bg2);
    padding: 6rem 1rem;
}

.furniture-rental--upgrade--grid {
    display: grid;
    row-gap: 2rem;
    column-gap: 3rem;
}

.furniture-rental--upgrade--left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    margin: auto;
    max-width: 900px;
}

.furniture-rental--upgrade--right {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    justify-content: center;
    padding: 1rem;
}

.furniture-rental--upgrade--right img{
    width: 100%;
}

@media (min-width: 1080px) {
    .furniture-rental--upgrade--grid {
        
    }
}

