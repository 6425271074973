.ready {
    background-color: #F8F0E6;
    margin-top: 8rem;
    padding: 4rem 1rem;
}

.ready--container {
    max-width: 1200px;
    margin: auto;
    display: grid;
    gap: 2rem;
}

.ready--left {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.ready--left p {
    font-size: 1.4rem;
}

.ready--left a {
    margin-top: 2rem;
    border-radius: 2rem;
}

.ready--right img {
    width: 100%;
}

.cta {
    font-size: 1.7rem;
    margin: auto;
}

@media (min-width: 1024px) {
    .ready--container {
        grid-template-columns: 2fr 3fr;
    }
    .ready--left {
        text-align: left;

    }
}