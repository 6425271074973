.hero {
}

.hero--container {
    padding: 1rem;
    max-width: 1200px;
    margin: auto;
}

.title--container h2 {
    padding: 0 1rem;
}

.img--container {
    margin-top: 3rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

}

.img--container img {
    width: 100%;
    border-radius: .25rem;
}

.title--container {
    max-width: 800px;
    margin: auto;
}

.title--container span {
    display: block;

}

.hero--text p {
    margin: 2rem auto 0 auto;
    max-width: 800px;
    font-size: 1.2rem;
    line-height: 3rem;
    padding: 0 1rem;
}


@media (min-width: 768px) {
    .img--container {
        grid-template-columns: repeat(4, 1fr);
    }

    .hero--text p {
        font-size: 1.5rem;

    }
}

@media (min-width: 1024px) {
    .hero--text p {
        font-size: 1.7rem;

    }
}
