.car-rental--fleet {
    max-width: 1200px;
    margin: 2rem auto 0 auto;
    padding: 1rem;
}

.car-rental--fleet--heading {
    display: grid;
    gap: 1rem;
    margin-top: 3rem;
}

.car-listings {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.car-listing {
    display: grid;
    gap: 1rem;
}

@media (min-width: 768px) {
    .car-rental--fleet--heading {
        grid-template-columns: 1fr 1fr;
    }
    .car-listing {
        grid-template-columns: 1fr 1fr;
    }
}

.car-listing--title {
    transition: all .25s ease;
}

.car-listing--title:hover {
    transform: translate(0, -.25rem);
}

.car-listing img {
    border-radius: .25rem;
}

.car-listing--left {
    display: flex;
    flex-direction: column;
    gap: 1rem;  
}

.car-listing--right img {
    width: 100%;
}


.car-listing--location {
    display: flex;
    gap: 1rem;
}

.car-listing--price {
    font-weight: 600;
}

.car-listing--location img {
    width: 32px;
    height: 32px;
}

.car-listing--thumbnail {
    transition: all .25s ease;
}

.car-listing--thumbnail:hover {
    scale: 1.025;
}