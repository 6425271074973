.car-rental h2 {
    max-width: 13ch;
}



@media (min-width: 768px) {
    .car-rental section {
        margin-top: 4rem;
    }
}

.car-rental--hero {
    background-image: url('https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_17.png?v=1716398793');
    background-position: 50% 40%;
    
}

.page-hero--cta {
    display: flex;
    flex-direction: column;
}

.car-rental--intro {
}

.car-rental--intro--grid {
    max-width: 1200px;
    margin: auto    ;
    display: grid;
    gap: 2rem;
    direction: rtl;
    text-align: left;
    padding: 1rem;
}

.car-rental--intro--grid img {
    width: 100%;
    margin: .25rem;
    border-radius: .25rem;
}

.car-rental-intro--left {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}

@media (min-width: 768px) {
    .car-rental--intro--grid {
        grid-template-columns: 1fr 1fr;
    }
    .car-rental-intro--left {
        flex-direction: column;
    }
    
}

.pickup {
    margin-top: 3rem;
}

.pickup--grid {
    display: grid;
    max-width: 1200px;
    margin: auto;
    padding: .5rem;
    gap: 1rem;
}



.pickup--left ul {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pickup--left li {
    display: flex;
    gap: 1rem;
    list-style: none;
    align-items: center;
}

.pickup--left li img {
    width: 28px;
    height: 28px;
}

.pickup--right img {
    width: 100%;
}

@media (min-width: 768px) {
    .pickup--grid {
        grid-template-columns: 1fr 1fr;
    }
}

.book-car {
    background-color: #F8F0E6;
    padding: 6rem;
}

.book-car--grid {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    column-gap: 3rem;  
    row-gap: 2rem;
}

.book-car--right {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.book-car--right a {
    width: fit-content;
}

@media (min-width: 1200px) {
    .book-car--grid {
        flex-direction: row;
    }
}