.footer {
    background-color: #FDF6E7;
    padding: 2rem 1rem;
    min-height: 400px;
    text-align: center;
    display: grid;
    place-content: center;
}

.footer--logo {
    width: 160px;
    margin: auto;
}

.footer--logo img {
    width: 100%;
}

.footer--menu {
    margin-top: 4rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer--credit {
    margin-top: 6rem;
}