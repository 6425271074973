.our-services {
    max-width: 1024px;
    margin: 4rem auto 0 auto;
    padding: 0 1rem;
}

.our-services h3 {
    text-align: center;
}


.services--container {
    margin-top: 4rem;
    display: grid;
    row-gap: 2rem;
    column-gap: 1rem;
    place-content: center;
}

.service--container {
    border: 1px solid silver;
    border-radius: 1rem;
    max-width: 450px;
}

.service--container--info {
    padding: 1rem 2rem ;
}

.service--container h4 {
    text-align: center;
    margin-bottom: 2rem;
}

.service--container img {
    width: 100%;
}

@media (min-width: 768px) {
    .services--container {
        grid-template-columns: 1fr 1fr;
    }
}
